import {InviteUser} from "../interface/user";
import { HttpClient , HttpHeaders} from '@angular/common/http';
import {FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {RegisterService} from "../service/register/register.service";
import {ErrorHandler, Injectable} from "@angular/core";
import {ToastService} from "ng-zorro-antd-mobile";
import {AuthService} from "../service/auth/auth.service";

@Injectable({
    providedIn: 'any'
})

export class RegisterClass {
    // 注册类型
    private RegisterType: 'shop' | 'user';
    private RegisterStatus: 'email' | 'phone';

    // 0 第一步 1 第二步
    public status: number = 0;

    // 表单实现和表单验证
    registerForm = new FormGroup({})
    phoneValidators = [Validators.required, Validators.minLength(11), Validators.maxLength(11)];
    codeValidators = [Validators.required];
    emailValidators = [Validators.required, Validators.email];
    passwordValidators = [Validators.required, Validators.minLength(8)]

    // 表单value
    areaData: string = '86';
    name: string = '';
    showCode = false;

    // 提交需要的数据
    openId: string = null;
    inviteCode: string = null;
    // 邀请人
    userInfo: InviteUser[] = [];

    inviteUser: InviteUser

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private register: RegisterService,
        private toast: ToastService,
        private auth: AuthService,
        private http: HttpClient
    ) {
    }

    /**
     * 初始化
     * @param RegisterType
     * @param RegisterStatus
     */
    public onInit(RegisterType, RegisterStatus) {
        if (this.auth.isLoggedIn()) {
            this.router.navigateByUrl('/simpleherbalzone/home').then()
        } else {
            this.RegisterStatus = RegisterStatus;
            this.RegisterType = RegisterType;
            this.register.getUserInfoByCode(this.inviteCode).subscribe(res => {
                this.openId = res.data.open_id;
                this.inviteUser = {
                    ...res.data,
                    status: 0
                }
            })
        }
    }

    public OnDestroy() {
        this.registerForm.clearValidators();
    }

    /**
     * 检查邀请状态
     */
    private checkStatus(): void {
        this.register.checkInviteStatus[this.RegisterType][this.RegisterStatus](
            this.registerForm.value[this.RegisterStatus],
            this.areaData
        ).subscribe(res => {
            const data = res.data;
            this.name = data.my_nickname ? data.my_nickname : '';
            if (!data.parent_user.open_id && data.my_open_id !== this.openId) {
                this.inviteUser.status = 1;
            } else {
                this.inviteUser.status = 2;
            }
            this.status = 1;
        });
    }

    /**
     * 注册
     */
    private async onRegister(): Promise<void> {
        this.showCode = await this.register.register[this.RegisterType][this.RegisterStatus](
            Object.assign(this.registerForm.value, {
                area: this.areaData,
                inviteCode: this.inviteCode
            })
        )
    }


    // 提交防抖
    isSubmit = true

    /**
     * 提交
     */
    public submit(): void {
        if (this.isSubmit) {
            if (this.registerForm.valid) {
                if (this.status === 0) {
                    this.checkStatus()
                } else {
                    this.isSubmit = false
                    setTimeout(() => {
                        this.isSubmit = true
                    }, 500)
                    this.onRegister()
                }
            } else {
                const inputNameMap = {
                    code: '验证码',
                    phone: '手机',
                    email: '邮箱',
                    password: '密码'
                }
                const inputErrorMap = {
                    minlength: '长度太短',
                    maxlength: '长度太长',
                    email: '输入格式不正确'
                }
                try {
                    Object.keys(this.registerForm.controls).forEach(key => {
                        const controlErrors = this.registerForm.get(key).errors;
                        if (controlErrors != null) {
                            Object.keys(controlErrors).forEach(keyError => {
                                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                                if (keyError === 'required') {
                                    this.toast.show('还有信息未完善');
                                    throw new Error("EndIterative");
                                } else {
                                    this.toast.show(`${inputNameMap[key]}${inputErrorMap[keyError]}`)
                                    throw new Error("EndIterative");
                                }
                            });
                        }
                    });
                } catch (e) {
                }
            }
        }
    }
}
